'use strict';

(function() {
  function ActivityLogResource($resource) {
    return $resource('api/v1/activity-log/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      
      addActivityLog: {
        method: 'POST',
      },
      filterActivityLog: {
        method: 'POST',
        params: {
          controller: 'filter-data'
        },
      }
    });
  }

  angular.module('windmanagerApp')
    .factory('ActivityLog', ActivityLogResource);
})();
